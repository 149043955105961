import styled from 'styled-components'

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;

  > * {
    text-align: center;
  }

  h2 {
    font-size: 22px;
    text-align: center;
  }
`

export const StyledCardWrapper = styled.section`
  width: 100%;
  max-width: 595px;
`