export const checkFormFields = (formFields) => {
  const formKeys = Object.keys(formFields)
  const hasEveryField = formKeys.every((field) => formFields[field])

  const hasMinimumAccountNumberLength = formFields?.accountNumber?.length >= 4
  const hasMinimumBankBranchLength = formFields?.bankBranch?.length >= 4

  return (
    hasEveryField &&
    formKeys.length > 3 &&
    hasMinimumAccountNumberLength &&
    hasMinimumBankBranchLength
  )
}
