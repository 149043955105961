import { useFeatureValue } from '@growthbook/growthbook-react'
import { EnvConfig } from '../config/EnvConfig'
import turnstileScript from '../templates/externalScripts/turnstileScript'
import { useEffect } from 'react'

let isAlreadyInitialized = false

export const useTurnstile = () => {
  const captchaTurnstileOn =
    useFeatureValue('captcha_registration_solution') === 'TURNSTILE'

  const initializeTurnstile = (
    onCaptchaVerified,
    useEffectCondition,
    renderCondition
  ) => {
    useEffect(() => {
      if (!captchaTurnstileOn || renderCondition) {
        return () => (isAlreadyInitialized = false)
      }

      const turnstileWrapper = document.getElementById('turnstilCaptcha')
      const alreadyHasElements = turnstileWrapper?.children?.length >= 1
      if (isAlreadyInitialized || alreadyHasElements) return
      isAlreadyInitialized = true

      const turnstileRender = () => {
        window.turnstile.render('#turnstilCaptcha', {
          action: 'REGISTRATION',
          sitekey: EnvConfig.GATSBY_CLOUDFARE_CAPTCHA_SITEKEY,
          theme: 'light',
          callback: onCaptchaVerified,
        })
      }

      const script = turnstileScript(turnstileRender)
      document.head.appendChild(script)

      // Cleanup the script when the component unmounts
      return () => {
        if (script && document.head.contains(script)) {
          document.head.removeChild(script)
          isAlreadyInitialized = false
        }
      }
    }, [captchaTurnstileOn, useEffectCondition])
  }

  return {
    captchaTurnstileOn,
    initializeTurnstile,
  }
}
