import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2.375rem;
  padding: 1rem;

  ${themeMedia.desktop`
    padding: 0;
  `};
`

export const HeaderTitle = styled.h1`
  font-size: 25px;
  font-weight: 400;
  line-height: 28.75px;
  text-align: center;
  margin: 0;
`

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  small {
    padding: 0.5rem 0;
    text-align: center;
    display: block;
  }

  ${themeMedia.desktop`
        margin: 0 auto;
        padding: 0;
    `};
`

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;

  > * {
    text-align: center;
  }

  h2 {
    font-size: 22px;
    text-align: center;
  }
`

export const StyledStepperWrapper = styled.section`
  max-width: 330px;
  width: 100%;
  position: relative;
`
