import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import EmailIcon from '../../../../../images/icon_email_outline_gray.svg'
import { VerifyEvery60SecondsContent } from '../../../../molecules/verifyEvery60SecondsContent'
import { useTranslation } from '../../../../../context/translationProvider'
import {
  doSendValidationEmail,
  doValidateEmail,
} from '../../../../../adapters/auth'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { toast } from '../../../../atoms'
import { removeNationalIdMask } from '../../../../../helpers/mask'
import { RegisterChangeValueContent } from '../../customComponents/registerChangeValueContent'
import { Field } from '../../../../molecules/field'
import { emailRegex } from '../../../../../utils/emailValidation'

const EmailVerificationStep = (props) => {
  const { formProperties, onCompleteStep, onInputFocus, onInputBlur } = props
  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = formProperties

  const { translate } = useTranslation()
  const duration = useFeatureValue('registration_timer_to_resend')
  const email = getValues('email')
  const [isChangingValue, setIsChangingValue] = useState()

  useEffect(() => {
    if (isChangingValue) {
      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'registrationw_email_edit_page_viewed',
        })
      }
    } else {
      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'registrationw_email_page_viewed',
        })
      }
    }
  }, [isChangingValue])

  const onResend = async () => {
    const email = getValues('email')
    const languageCode = getValues('languageCode')
    const national_id = getValues('national_id')

    const { ok, error } = await doSendValidationEmail(
      email,
      removeNationalIdMask(national_id),
      languageCode
    )
    if (!ok) {
      switch (error.messageCode) {
        case 186:
          toast.error(
            translate(
              'register.error.the_username_already_taken_please_select_another'
            )
          )
          break
        default:
          toast.error(translate('registration.error.email.sending'))
          break
      }
    }

    return ok
  }

  const onInputFieldDone = async ({ verification_code }) => {
    const email = getValues('email')
    const national_id = getValues('national_id')

    const { ok } = await doValidateEmail(
      email,
      removeNationalIdMask(national_id),
      verification_code
    )
    if (!ok) {
      return toast.error(translate('registration.error.email.validating'))
    }

    return onCompleteStep()
  }

  const onChangeEmail = async () => {
    const isValid = await trigger('email')
    if (!isValid) return

    const isSuccessful = await onResend()
    if (isSuccessful) {
      setIsChangingValue(false)
    }
  }

  if (isChangingValue) {
    return (
      <RegisterChangeValueContent
        onSubmit={onChangeEmail}
        icon={EmailIcon}
        ctaText={translate('verification.email.changeValue.button')}
        title={translate('verification.email.changeValue.title')}
        description={translate('verification.email.changeValue.description')}
        renderField={() => (
          <Field
            label={translate('userProfile.email')}
            description={translate('userProfile.emailHint')}
            maxLength="100"
            type="email"
            autoCompleteOff
            showErrorMsg
            errors={errors}
            onFocus={onInputFocus}
            onBlur={() => onInputBlur('email_input_timer')}
            formProperties={register('email', {
              required: true,
              pattern: {
                value: emailRegex,
                message: translate('register.error.incorrectEmailFormat'),
              },
            })}
          />
        )}
      />
    )
  }

  const didDoChangeValueBefore = isChangingValue === false

  return (
    <VerifyEvery60SecondsContent
      useResendOnStart={!didDoChangeValueBefore}
      onResend={onResend}
      icon={EmailIcon}
      onInputFieldDone={onInputFieldDone}
      duration={duration}
      ctaText={translate('verification.email.buttonText')}
      title={translate('verification.email.title')}
      changeValueLink={translate('verification.email.changeValue.link')}
      onChangeValueClicked={() => setIsChangingValue(true)}
      description={translate('verification.email.description').replaceAll(
        '{email}',
        email
      )}
      tickingText={translate('verification.email.60seconds')}
      didntReceiveText={translate('verification.email.clickBelow')}
    />
  )
}

EmailVerificationStep.defaultProps = {
  formProperties: {},
  onCompleteStep: () => {},
  onInputFocus: () => {},
  onInputBlur: () => {},
  stepIsDone: false,
}

EmailVerificationStep.propTypes = {
  formProperties: PropTypes.object,
  onCompleteStep: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  stepIsDone: PropTypes.bool,
}

export { EmailVerificationStep }
