import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { captureException } from '@sentry/browser'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useRegisterModel } from '../../../utils/formModelGetters/registerModel'
import { parseQueryString, isBrowser } from '../../../utils/generic'
import { cookiePrefix, getCookie } from '../../../utils/cookies'
import { isCouponValid } from '../../../utils/couponValidation'
import { stringNormalize } from '../../../utils/stringNormalize'
import useFeatures from '../../../hooks/useFeatures'
import useSlugs from '../../../hooks/useSlugs'
import useQueryParams from '../../../hooks/useQueryParams'
import { useReCaptcha } from '../../../hooks/useReCaptcha'

import allLocales from '../../../i18n/locales'
import { doRegistration } from '../../../adapters/auth'
import { AuthContext } from '../../../context/authProvider'
import { useTranslation } from '../../../context/translationProvider'

import AboutYouIcon from '../../../images/icon_user.svg'
import AddressIcon from '../../../images/icon_address.svg'
import VerificationIcon from '../../../images/icon_shield.svg'

import { FormBanner } from '../formBanner'
import { If } from '../../atoms/if'
import { Message } from '../../atoms/message'
import { ProgressBar } from '../../atoms/progressBar'
import { toast } from '../../atoms/toast'
import {
  AddressStep,
  EmailAndPasswordStep,
  EmailVerificationStep,
  PhoneVerificationStep,
  SelfieAndIdStep,
} from './steps'
import {
  StyledContent,
  StyledErrorMessageWrapper,
  StyledRecaptchaWrapper,
} from './styles'
import { triggerFormFields } from '../../../utils/formUtils'
import { useRegisterData } from '../../../hooks/useRegisterData'
import storageService from '../../../services/storageService'
import { useBannersApiData } from '../../../hooks/graphqlStaticQuery/useBannersApiData'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import { useSessionStorage } from '../../../hooks/useSessionStorage'
import { msToMinutesAndSeconds } from '../../../helpers/msToMinutesAndSeconds'
import {
  REGISTRATION_STEP_IDS,
  REGISTRATION_STEPS,
} from '../../../hooks/useRegistrationSteps'

import { isFbUser } from '../../../helpers/urlHelper'
import { FB_USER_QUERY_PARAM } from '../../../utils/constants'
import { FirstStep } from './oldSteps/firstStep'
import { SecondStep } from './oldSteps/secondStep'
import { ThirdStep } from './oldSteps/thirdStep'
import { getRegistrationDates } from '../../../utils/datetime'
import { useGroowthbookLocaleFeature } from '../../../hooks/useGrowthbook'
import { RecaptchaMessage } from '../../molecules/recaptchaMessage'
import { useTurnstile } from '../../../hooks/useTurnstile'
import { RegisterTopBanner } from '../RegisterTopBanner'

const locale = process.env.GATSBY_INTL_LOCALE

const RegisterContent = (props) => {
  const dates = useMemo(() => getRegistrationDates(), [])
  const { referrerCode = null, nationalId = null } = useQueryParams()
  const {
    location,
    formStep,
    goToNextStep,
    goBack,
    isLastStep,
    layout,
    prepopulatedInputs = nationalId
      ? { ...props.prepopulatedInputs, national_id: nationalId }
      : props.prepopulatedInputs,
  } = props
  const { translate } = useTranslation()
  const [slides] = useBannersApiData(['registration'])
  const { isMobile } = useDeviceDetect()
  const { registrationBlocked, loginUser } = useContext(AuthContext)
  const params = parseQueryString(location?.search ?? '')
  const isEmailVerificationEnabled = useFeatures('emailVerification')
  const isFirstLoginEnabled = useFeatures('firstLogin')
  const promoCode = params?.promoCode || getCookie(`${cookiePrefix}promoCode`)
  const isMgaLicense = useFeatures('mgaLicense')
  const { getRegisterModel } = useRegisterModel()

  // const uspsData = [
  //   { icon: tickIcon, title: translate('usps.earlyGain') },
  //   { icon: tickIcon, title: translate('usps.greatOdds') },
  //   { icon: tickIcon, title: translate('usps.InstantWithdrawal') },
  // ]

  const couponErrors = {
    [439]: translate('promoCode.error.notExist'),
    [440]: translate('promoCode.error.notInUse'),
    [441]: translate('promoCode.error.expired'),
    default: translate('promoCode.error.notExist'),
  }

  const [sportSlug, accountProfileSlug] = useSlugs([
    'sports',
    'account/profile',
  ])

  const formProperties = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const { getValues, setValue, trigger, setError } = formProperties

  const [cafData, setCafData] = useState(null)
  const [response, setResponse] = useState(null)
  const [loading, setLoading] = useState(false)
  const [languages, setLanguages] = useState([])
  const [phoneCodes, setPhoneCodes] = useState([])
  const [mobilePrefix, setMobilePrefix] = useState()
  const [captchaToken, setCaptchaToken] = useState('')
  const [whitelistedCountries, setWhitelistedCountries] = useState('')
  const [country, setCountry] = useState()
  const [regions, setRegions] = useState([])
  const [cities, setCities] = useState([])
  const [friendReferrerCode, setFriendReferrerCode] = useState(null)
  const [lastFocusedFieldTime, setLastFocusedFieldTime] = useState(null)
  const checkNationalId = useFeatureIsOn(
    'player_registration_national_id_check'
  )
  const useGoogleForms = useFeatureIsOn(
    'player_registration_google_maps_address'
  )

  // TODO: After January 1st, remove everything that relies on the old flow
  const useOldFlow = !useFeatureIsOn('registration_new_flow')
  const isReferAFriendEnabled = useGroowthbookLocaleFeature(
    'fe_igp_refer_a_friend'
  )
  const [stepsDone, setStepsDone] = useState({})

  const { initializeTurnstile, captchaTurnstileOn } = useTurnstile()

  const onCaptchaVerified = useCallback((data) => {
    setCaptchaToken(data)
  }, [])
  initializeTurnstile(onCaptchaVerified, formStep, isLastStep)

  const { safeSetCountry, countries, nationalities } = useRegisterData({
    isMgaLicense,
    country,
    setValue,
    setLanguages,
    setPhoneCodes,
    setMobilePrefix,
    setCountry,
    setRegions,
    setCities,
    setWhitelistedCountries,
    useGoogleForms,
  })
  const { setSessionValue, getSessionValue } = useSessionStorage()

  const renderErrorMessage = useMemo(() => {
    switch (response?.error?.messageCode) {
      case 42:
        return translate('register.error.mobileUsed')
      case 48:
        return translate('register.error.ageCheck')
      case 64:
        return translate('register.alreadyExists')
      case 299:
        return translate('register.keycloakTimeout')
      case 65:
        return translate('register.error.existingDetails')
      default:
        if (isEmailVerificationEnabled) return response?.error?.message
        return translate('accountActivation.accountActivatedError')
    }
  }, [response, isEmailVerificationEnabled])

  useEffect(() => {
    const btag = getCookie('btag')
    const couponTag = promoCode || btag

    if (couponTag.length >= 4 && couponTag.length <= 8) {
      setValue('couponCode', stringNormalize(couponTag))
    }
  }, [setValue, promoCode])

  useEffect(() => {
    if (!isBrowser()) return
    setSessionValue(`${cookiePrefix}:registration_start`, new Date().getTime())

    if (isReferAFriendEnabled) {
      const persistedReferrerCode = sessionStorage.getItem(
        `${cookiePrefix}referrer_code`
      )
      const aux = referrerCode || persistedReferrerCode || null
      setFriendReferrerCode(aux)
    }
  }, [])

  useEffect(() => {
    window?.dataLayer?.push({ event: `registration_start_${layout}` })

    const isReturningUser = storageService.getValue('firstRegistration')

    if (isReturningUser) {
      window?.dataLayer?.push({ event: 'returning_customer_registration' })
    } else {
      storageService.setValue('firstRegistration', true)
      window?.dataLayer?.push({ event: 'new_customer_registration' })
    }
  }, [layout])

  if (registrationBlocked) return <></>

  const registerTime = (key, spentTimeInMs) => {
    window?.dataLayer?.push({
      event: key,
      timeSpent: msToMinutesAndSeconds(spentTimeInMs),
    })
  }

  const onInputFocus = () => {
    setLastFocusedFieldTime(new Date().getTime())
  }

  const onInputBlur = (field) => {
    const endTime = new Date().getTime()
    const spentTimeInMs = endTime - (lastFocusedFieldTime || 0)
    registerTime(field, spentTimeInMs)

    setLastFocusedFieldTime(null)
  }

  const onSubmit = async () => {
    if (!country || !isLastStep) return
    const model = await getRegisterModel(
      captchaTurnstileOn && captchaToken,
      formProperties,
      friendReferrerCode,
      country
    )

    if (!model) return toast.error(translate('common.unexpectedError'))
    if (!model.afId) {
      if (!model.nationalId) {
        captureException('User signed up with no afId, and no cpf')
      } else {
        captureException(
          'User signed up with no afId, the cpf is: ' + model.nationalId
        )
      }
    }
    setLoading(true)

    const response = await doRegistration(model, useOldFlow)
    if (response.ok) {
      const couponError = await validateCoupon(model.affiliate_marker)
      setResponse(response)
      storageService.setValue('registrationCompleted', true)

      const startTime = getSessionValue(`${cookiePrefix}:registration_start`)
      const endTime = new Date().getTime()
      const timeSpent = msToMinutesAndSeconds(endTime - startTime)

      window?.dataLayer?.push({
        event: 'total_registration_time_spent',
        totalTime: timeSpent,
      })

      if (isEmailVerificationEnabled) {
        goToNextStep()
      } else {
        if (isFirstLoginEnabled) storageService.setValue('firstLogin', true)
        await loginUser({ loginData: response })
        const user = storageService.getUser()
        window?.dataLayer?.push({
          event: 'Registration',
          accountId: user?.id,
        })
        if (isMgaLicense) {
          navigate(`/${accountProfileSlug}/`)
        } else {
          if (window.location?.pathname?.includes(`/${sportSlug}/`)) {
            window.location.reload()
          } else {
            navigate(`/${sportSlug}/${isFbUser() ? FB_USER_QUERY_PARAM : ''}`)
          }
        }

        storageService.removeValue('firstRegistration')
        if (couponError && model.affiliate_marker?.length) {
          console.error(couponError)
        }

        return response
      }
    } else {
      setResponse(response)
      if (response.error.messageCode === 211) {
        const nationalIDField = 'national_id'
        setError(nationalIDField, {
          message: translate('cpf.register.invalidValue'),
        })
      }
    }

    setLoading(false)
  }

  const recaptchaSubmit = useReCaptcha(onSubmit, 'REGISTRATION')

  const validateCoupon = async (value) => {
    if (!value) return
    const { messageCode } = await isCouponValid(value)
    if (messageCode) return couponErrors[messageCode] || couponErrors.default
  }

  const triggerStepGTMEvent = () => {
    window.dataLayer?.push({ event: `register_${layout}_step_${formStep}` })
  }

  const onCompleteStep = async (fieldsToTrigger, aditionalChecks) => {
    if (fieldsToTrigger && !(await triggerFormFields(trigger, fieldsToTrigger)))
      return
    if (aditionalChecks && !(await aditionalChecks())) return

    triggerStepGTMEvent()
    if (isLastStep) {
      return recaptchaSubmit()
    } else {
      setStepsDone((state) => ({ ...state, [formStep]: true }))
      goToNextStep()
    }
  }

  const createRegisterSteps = () => {
    const commonProps = {
      formProperties,
      onCompleteStep,
      country,
      onInputFocus,
      onInputBlur,
      prepopulatedInputs,
      registerTime,
      setStepsDone,
    }
    switch (REGISTRATION_STEPS[formStep].id) {
      case REGISTRATION_STEP_IDS.OLD_EMAIL_AND_PASS:
        return (
          <FirstStep
            {...commonProps}
            setCafData={setCafData}
            setToken={setCaptchaToken}
            checkNationalId={checkNationalId}
          />
        )
      case REGISTRATION_STEP_IDS.OLD_CONFIRM_NAME:
        return (
          <SecondStep
            {...commonProps}
            dates={dates}
            goBack={goBack}
            cafData={cafData}
            checkNationalId={checkNationalId}
          />
        )
      case REGISTRATION_STEP_IDS.OLD_ADDRESS:
        return (
          <ThirdStep
            {...commonProps}
            setters={{
              setMobilePrefix,
            }}
            data={{
              phoneCodes,
              mobilePrefix,
              countries,
              regions,
            }}
            loading={loading}
            useGoogleForms={useGoogleForms}
            friendReferrerCode={friendReferrerCode}
            whitelistedCountries={whitelistedCountries}
            isEmailVerificationEnabled={isEmailVerificationEnabled}
            safeSetCountry={safeSetCountry}
            captchaToken={captchaToken}
            setToken={setCaptchaToken}
            responseError={response?.error}
          />
        )
      case REGISTRATION_STEP_IDS.EMAIL_AND_PASS:
        return (
          <EmailAndPasswordStep
            {...commonProps}
            setCafData={setCafData}
            setToken={setCaptchaToken}
            checkNationalId={checkNationalId}
            nationalities={nationalities}
          />
        )
      case REGISTRATION_STEP_IDS.SELFIE_AND_ID:
        return (
          <SelfieAndIdStep
            {...commonProps}
            goBack={goBack}
            cafData={cafData}
            stepIsDone={stepsDone[formStep]}
            setCafData={setCafData}
          />
        )
      case REGISTRATION_STEP_IDS.ADDRESS:
        return (
          <AddressStep
            {...commonProps}
            data={{
              phoneCodes,
              mobilePrefix,
              countries,
              regions,
              cities,
            }}
            setMobilePrefix={setMobilePrefix}
            loading={loading}
            useGoogleForms={useGoogleForms}
            friendReferrerCode={friendReferrerCode}
            whitelistedCountries={whitelistedCountries}
            isEmailVerificationEnabled={isEmailVerificationEnabled}
            safeSetCountry={safeSetCountry}
            captchaToken={captchaToken}
            responseError={response?.error}
          />
        )
      case REGISTRATION_STEP_IDS.CONFIRM_PHONE:
        return (
          <PhoneVerificationStep
            {...commonProps}
            mobileFieldData={{ phoneCodes, mobilePrefix }}
            setMobilePrefix={setMobilePrefix}
            getValues={getValues}
            onCompleteStep={onCompleteStep}
            stepIsDone={stepsDone[formStep]}
          />
        )
      case REGISTRATION_STEP_IDS.CONFIRM_EMAIL:
        return (
          <EmailVerificationStep
            {...commonProps}
            onCompleteStep={onCompleteStep}
            stepIsDone={stepsDone[formStep]}
          />
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    const langCode = allLocales[locale].code
    const language = languages.find((l) => l.code.toLowerCase() === langCode)
    setValue('languageCode', language?.code)
  }, [languages])

  const { showUspsBarMobile, showUspsBarDesktop, showBannerOnMobile } =
    REGISTRATION_STEPS[formStep]
  const showBanner = !isMobile || showBannerOnMobile
  const showUspsBar =
    (isMobile && showUspsBarMobile) || (!isMobile && showUspsBarDesktop)

  return (
    <StyledContent
      addExtraPadding={!showBanner}
      className="keyboard-padding-container"
    >
      <If
        condition={showBanner}
        render={() => (
          <FormBanner
            slides={slides}
            hideMobileNextButton
            mobileBannerHeight={190}
          />
        )}
      />
      <form id="register-form" onSubmit={(e) => e.preventDefault()}>
        <If
          condition={showUspsBar}
          render={() => (
            <RegisterTopBanner />
          )}
        />
        <ProgressBar
          useOldFlow={useOldFlow}
          formStep={formStep}
          goBack={goBack}
          steps={[
            {
              text: translate('registration.progress.aboutYou'),
              icon: AboutYouIcon,
            },
            {
              text: translate('registration.progress.address'),
              icon: AddressIcon,
            },
            {
              text: translate('registration.progress.verification'),
              icon: VerificationIcon,
            },
          ]}
        />
        {createRegisterSteps()}
        <If
          condition={
            response &&
            !response.ok &&
            response?.error?.messageCode !== 211 &&
            !loading
          }
          render={() => (
            <StyledErrorMessageWrapper>
              <Message error text={renderErrorMessage} id="errorMessage" />
            </StyledErrorMessageWrapper>
          )}
        />

        <If
          condition={!useOldFlow && isLastStep}
          render={() => (
            <>
              <div className="checkbox">
                <div id="turnstilCaptcha"></div>
              </div>
              <StyledRecaptchaWrapper>
                <RecaptchaMessage />
              </StyledRecaptchaWrapper>
            </>
          )}
        />
      </form>
    </StyledContent>
  )
}

RegisterContent.propTypes = {
  formStep: PropTypes.number,
  goBack: PropTypes.func,
  isLastStep: PropTypes.bool,
  layout: PropTypes.string,
  location: PropTypes.object,
  prepopulatedInputs: PropTypes.object,
  goToNextStep: PropTypes.func,
}

export { RegisterContent }
