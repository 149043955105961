import React from 'react'
import PropTypes from 'prop-types'
import { BackButton } from '../backButton'
import { If } from '../if'
import {
  StyledProgressBarStep,
  StyledOuterWrapper,
  StyledStepsWrapper,
  StyledBackButtonWrapper,
} from './styles'
import { REGISTRATION_STEPS } from '../../../hooks/useRegistrationSteps'

const ProgressBar = (props) => {
  const { formStep, steps, goBack, useOldFlow, customLeftButtonPosition } =
    props
  const { progress, hideBackButton } = REGISTRATION_STEPS[formStep] ?? {}

  if (progress > steps.length) return <></>
  return (
    <StyledOuterWrapper>
      <StyledBackButtonWrapper>
        <If
          condition={!hideBackButton}
          render={() => (
            <BackButton
              whiteBackground
              onClick={goBack}
              left={customLeftButtonPosition ? customLeftButtonPosition : '0'}
            />
          )}
        />
      </StyledBackButtonWrapper>
      <StyledStepsWrapper>
        {steps.map((step, i) => {
          const index = i + 1
          return (
            <StyledProgressBarStep
              key={`progress-step-${index}`}
              isDone={progress > index}
              isActive={progress == index}
              useOldFlow={useOldFlow}
              icon={step.icon}
              text={step.text}
            >
              {index}
            </StyledProgressBarStep>
          )
        })}
      </StyledStepsWrapper>
    </StyledOuterWrapper>
  )
}

ProgressBar.propTypes = {
  formStep: PropTypes.number,
  steps: PropTypes.array,
  goBack: PropTypes.func,
  useOldFlow: PropTypes.bool,
}

export { ProgressBar }
