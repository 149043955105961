import React from 'react'

export const BankAccountStatusIcon = () => {
  return (
    <div>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#EEEEEE" />
        <path
          d="M29.58 17.7475L20.47 11.1875C20.12 10.9375 19.64 10.9375 19.29 11.1875L10.41 17.7475C10.1 17.9775 9.97 18.3575 10.03 18.7275C10.03 18.7775 10 18.8175 10 18.8675V28.9975H30V18.8675C30 18.8675 29.98 18.7775 29.97 18.7275C30.04 18.3575 29.9 17.9675 29.58 17.7375V17.7475ZM19.89 13.2375L25.89 17.5575H14.04L19.89 13.2375ZM21.8 19.5575V26.9975H17.95V19.5575H21.8ZM12 19.5575H15.95V26.9975H12V19.5575ZM23.8 26.9975V19.5575H28V26.9975H23.8Z"
          fill="black"
        />
      </svg>
    </div>
  )
}
