import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import RefferAFriend from '../../../../../images/refer-a-friend/icon_refer-afriend-signup.svg'
import CouponIcon from '../../../../../images/icon_coupon.svg'
import { Field } from '../../../../molecules/field'
import { FieldDropdown } from '../../../../molecules/fieldDropdown'
import { GooglePlacesField } from '../../../../molecules/googlePlacesField'
import { Alert } from '../../../../molecules/alert'
import { Button } from '../../../../atoms/button'
import { FieldLabel } from '../../../../atoms/fieldLabel'
import { If } from '../../../../atoms/if'
import { Text } from '../../../../atoms/text'
import { brandConfig } from '../../../../../config/brandConfig'

import { StyledCol, StyledRow } from '../../styles'
import { validateCoupon } from '../../../../../helpers/validators'
import {
  nameNormalize,
  stringNormalize,
} from '../../../../../utils/stringNormalize'
import { formSubmitIsDisabled } from '../../../../../utils/formUtils'
import { useTranslation } from '../../../../../context/translationProvider'
import { StyledAddressStep } from '../../styles'
import { RegistrationCheckmarks } from '../../../../molecules/registrationCheckmarks'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { CombinedMobileField } from '../../customComponents/combinedMobileField'

const AddressStep = (props) => {
  const {
    formProperties,
    onCompleteStep,
    safeSetCountry,
    setMobilePrefix,
    data,
    friendReferrerCode,
    whitelistedCountries,
    loading,
    useGoogleForms,
    country,
    registerTime,
    onInputFocus,
    onInputBlur,
    setToken,
    responseError,
    setStepsDone,
  } = props
  const { countries, regions, cities } = data

  const {
    register,
    trigger,
    getValues,
    setValue,
    formState,
    watch,
    setError,
    clearErrors,
  } = formProperties
  const { errors } = formState

  const [stepTimer] = useState(new Date())
  const { translate } = useTranslation()
  const isShowCouponCode = useFeatureIsOn(
    'fe_igp_show_coupon_code_on_registration'
  )

  useEffect(() => {
    watch(['tandc', 'country', 'mobilePrefix', 'mobileNumber', 'privacyPolicy'])
  }, [])

  useEffect(() => {
    if (!responseError) return
    setToken(null)
  }, [responseError])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'registrationw_address_page_viewed',
      })
    }
  }, [])

  const setPromoCodeMask = (e) => {
    e.target.value = stringNormalize(e?.target?.value || '')
  }

  const handleCountryChange = async ({ target: { value } }) => {
    const country = countries.find((country) => country.code === value)
    safeSetCountry(country?.name)
  }

  const handleCompleteStep = () => {
    const actualTime = new Date()

    registerTime(
      'third_registration_step',
      actualTime.getTime() - stepTimer.getTime()
    )
    onCompleteStep([
      'tandc',
      'address',
      'city',
      'state',
      'country',
      'mobilePrefix',
      'mobileNumber',
    ])
  }

  const createGooglePlacesField = (key, fieldsToAffect, aditionalProps) => (
    <GooglePlacesField
      {...aditionalProps}
      whitelistedCountries={whitelistedCountries}
      formProperties={register(key, {
        required: aditionalProps?.required || false,
      })}
      errors={errors}
      label={translate(`userProfile.${key}`)}
      setValue={setValue}
      fieldsToAffect={fieldsToAffect}
      safeSetCountry={safeSetCountry}
      setError={setError}
      clearErrors={clearErrors}
    />
  )

  const fieldsToWatch = [
    'tandc',
    'privacyPolicy',
    'address',
    'city',
    'state',
    'country',
    'mobilePrefix',
    'mobileNumber',
    'belongHere',
  ]

  const createDropdown = (values, fieldName) => {
    return (
      <FieldDropdown
        key={`dropdown-${fieldName}`}
        autoCompleteOff
        errors={errors}
        label={translate(`userProfile.${fieldName}`)}
        fieldValue={getValues(fieldName)}
        onChange={(e) => {
          setValue(fieldName, e.target.value)
          clearErrors(fieldName)
        }}
        formProperties={register(fieldName, {
          required: values?.length > 0,
        })}
        values={values?.map((row, i) => (
          <option key={`${fieldName}-${i}`} value={nameNormalize(row.name)}>
            {row.name}
          </option>
        ))}
        onFocus={onInputFocus}
        onBlur={() => onInputBlur(`${fieldName}_input_timer`)}
      />
    )
  }

  return (
    <StyledAddressStep>
      <If
        condition={
          country?.code?.toUpperCase() !== 'BR' &&
          country?.currency_list?.length > 1
        }
        render={() => (
          <FieldDropdown
            autoCompleteOff
            errors={errors}
            label={translate('userProfile.currency')}
            formProperties={register('currency', { required: true })}
            removeFirstOption
            values={country?.currency_list.map((currency, i) => (
              <option value={currency.short_code} key={`currency-${i}`}>
                {currency.name} - ({currency.symbol || currency.short_code})
              </option>
            ))}
            onFocus={onInputFocus}
            onBlur={() => onInputBlur('currency_input_timer')}
          />
        )}
      />
      <FieldLabel name="mobilePrefix">
        {translate('userProfile.mobileNumber')}
      </FieldLabel>
      <CombinedMobileField
        formProperties={formProperties}
        setMobilePrefix={setMobilePrefix}
        onInputFocus={onInputFocus}
        onInputBlur={onInputBlur}
        setStepsDone={setStepsDone}
        data={data}
      />

      <If
        condition={useGoogleForms}
        render={() =>
          createGooglePlacesField(
            'address',
            [
              'street_number',
              'route',
              'postal_code',
              'postal_code_suffix',
              'locality',
              'country',
              'administrative_area_level_1',
              'administrative_area_level_2',
            ],
            {
              required: true,
              description: translate('register.startTyping'),
            }
          )
        }
        renderElse={() => (
          <StyledRow>
            <StyledCol>
              <Field
                autoCompleteOff
                errors={errors}
                label={translate('userProfile.address')}
                maxLength={128}
                minLength={3}
                onChange={() => trigger('address')}
                onFocus={onInputFocus}
                onBlur={() => onInputBlur('address_input_timer')}
                formProperties={register('address', { required: true })}
              />
            </StyledCol>
          </StyledRow>
        )}
      />

      {createDropdown(cities, 'city')}
      {createDropdown(regions, 'state')}

      <FieldDropdown
        autoCompleteOff
        errors={errors}
        label={translate('userProfile.country')}
        onChange={handleCountryChange}
        fieldValue={getValues('countryCode')}
        formProperties={register('countryCode', { required: true })}
        values={countries.map((row, i) => (
          <option key={i} value={row.code}>
            {row.translated_name}
          </option>
        ))}
        onFocus={onInputFocus}
        onBlur={() => onInputBlur('country_input_timer')}
      />

      <If
        condition={isShowCouponCode}
        render={() => (
          <If
            condition={!friendReferrerCode}
            render={() => (
              <Field
                errors={errors}
                showErrorMsg
                label={translate('userProfile.couponCode')}
                inputSuffix={<img src={CouponIcon} alt="Coupon" />}
                onChange={setPromoCodeMask}
                onFocus={onInputFocus}
                onBlur={() => onInputBlur('couponCode_input_timer')}
                maxLength="8"
                fieldValue={getValues('couponCode')}
                formProperties={register('couponCode', {
                  validate: (value) =>
                    validateCoupon(
                      value,
                      translate('promoCode.error.notExist')
                    ),
                })}
              />
            )}
            renderElse={() => (
              <Alert
                icon={RefferAFriend}
                description={translate('referAFriend.register.referWarn')}
              />
            )}
          />
        )}
      />

      <RegistrationCheckmarks formProperties={formProperties} />

      <Text style={{ textAlign: 'center' }}>
        <span
          dangerouslySetInnerHTML={{
            __html: translate('common.responsibleGaming'),
          }}
        />
      </Text>

      <Button
        id="joinUs"
        expand
        type="submit"
        style={{
          fontSize: brandConfig.fonts.text.button.size.desktop,
        }}
        disabled={
          loading || formSubmitIsDisabled(fieldsToWatch, getValues, errors)
        }
        onClick={handleCompleteStep}
        loading={loading}
      >
        {translate('register.next')}
      </Button>
      <If
        condition={loading}
        render={() => (
          <Text verticalPadding textAlign="left" theme="success">
            {translate('register.creatingMessage')}
          </Text>
        )}
      />
    </StyledAddressStep>
  )
}

AddressStep.propTypes = {
  formProperties: PropTypes.shape({
    register: PropTypes.func,
    trigger: PropTypes.func,
    getValues: PropTypes.func,
    setError: PropTypes.func,
    setValue: PropTypes.func,
    clearErrors: PropTypes.func,
    watch: PropTypes.func,
    formState: PropTypes.shape({ errors: PropTypes.object }),
  }),
  setMobilePrefix: PropTypes.func,
  data: PropTypes.shape({
    mobilePrefix: PropTypes.string,
    phoneCodes: PropTypes.array,
    countries: PropTypes.array,
    regions: PropTypes.array,
    cities: PropTypes.array,
  }),
  responseError: PropTypes.shape({
    message: PropTypes.string,
    messageCode: PropTypes.number,
  }),
  country: PropTypes.object,
  onCompleteStep: PropTypes.func,
  safeSetCountry: PropTypes.func,
  loading: PropTypes.bool,
  useGoogleForms: PropTypes.bool,
  friendReferrerCode: PropTypes.string,
  whitelistedCountries: PropTypes.string,
  registerTime: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  captchaToken: PropTypes.string,
  setToken: PropTypes.func,
  setStepsDone: PropTypes.func,
}

export { AddressStep }